import { head, isLength, last } from 'lodash'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Swiper, { Navigation, Pagination } from 'swiper'

const simpleParallax = require('simple-parallax-js')

export default {
	init () {
		// JavaScript to be fired on all pages

		$('.hamburger').on('click', function () {
			$(this).toggleClass('is-active')
			if ($(this).hasClass('is-active')) {
				$('body').addClass('menu-open')
				$('.topnav').addClass('open')
				$('.mbl-util-topnav').addClass('open')
				$('.scales-mobile').addClass('hide')
			} else {
				$('body').removeClass('menu-open')
				$('.topnav').removeClass('open')
				$('.mbl-util-topnav').removeClass('open')
				$('.scales-mobile').removeClass('hide')
			}
		})

		$(window).on('resize', function () {
			$('.topnav').attr('style', '')
			$('.hamburger').removeClass('is-active')
		})

		// $(window).on('scroll', function () {
		// 	if ($(window).scrollTop() > 0) {
		// 		$('header.header').addClass('fixed')
		// 	} else {
		// 		$('header.header').removeClass('fixed')
		// 	}
		// })

		/**
		 * A basic function to scroll to a specific element
		 * @param elm
		 * @param duration The duration for the scroll animation to execute
		 */
		const scrollTo = (elm, duration) => {
			elm.preventDefault()
			const scrollToElm = elm.currentTarget.getAttribute('href')

			if (
				scrollToElm.length &&
				document.querySelector(scrollToElm) !== null
			) {
				$('html, body').animate(
					{ scrollTop: $(scrollToElm).offset().top },
					parseInt(duration)
				)
			}
		}

		// Scroll To
		;(function () {
			$('.scrollto').on('click', elm => scrollTo(elm, 1000))
		})()

		/**
		 * Skip links
		 */
		// skip link scroll to section
		const skipToAnchor = aid => {
			var aTag = $(aid)
			var focus = true
			$('html,body').animate(
				{
					scrollTop: aTag.offset().top
				},
				'slow'
			)
			var first_child = $(aTag.children()[0])
			var tag = first_child.prop('tagName').toLowerCase()

			if (
				tag !== 'a' &&
				tag !== 'button' &&
				tag !== 'input' &&
				tag !== 'textarea'
			) {
				if (first_child.attr('tabIndex') !== undefined) {
					first_child.attr('tabIndex', -1).focus()
					first_child.removeAttr('tabIndex')
				} else {
					first_child.focus()
				}
			} else {
				first_child.focus()
			}
		}

		// create skip links
		const skipLinks = () => {
			$('section').each(function () {
				const id = $(this).attr('id')
				if (id !== undefined) {
					// Use the section id to create a label for the items in the skip link list
					var sectionNameArray = id.split('-')
					var sectionName = ''
					for (var i = 0; i < sectionNameArray.length; i++) {
						var str = sectionNameArray[i]
						str = str
							.toLowerCase()
							.replace(/\b[a-z]/g, function (letter) {
								return letter.toUpperCase()
							})
						sectionName += str
						if (i < sectionNameArray.length - 1) {
							sectionName += ' '
						}
					}
					var skiplink =
						"<li><a href='#" +
						id +
						"' class='text-link'>Skip to " +
						sectionName +
						'</a></li>'
					$('.skiplinks ul').append(skiplink)
				}
			})

			const skipLinkContainer = $('.skiplinks'),
				skipLink = $('.skiplinks a')

			skipLink.on('focus', function () {
				skipLinkContainer.addClass('show')
			})

			skipLink.on('blur', function () {
				skipLinkContainer.removeClass('show')
			})

			skipLink.on('click', function (e) {
				e.preventDefault()
				skipToAnchor($(this).attr('href'))
			})
		}

		skipLinks()

		var sectionWaypoints = $('section').each(function () {
			$(this).waypoint({
				handler: function (direction) {
					$(this.element).addClass('loaded')
				},
				offset: '50%'
			})

			if ($(this).hasClass('scroll-group')) {
				console.log('adding scroll group waypoints')
				$(this).waypoint({
					handler: function (direction) {
						if (direction === 'down') {
							$(this.element).addClass('fixed')
						} else {
							$(this.element).removeClass('fixed')
						}
					},
					offset: function () {
						return 100 + $('header.header').outerHeight()
					}
				})
				$(this).waypoint({
					handler: function (direction) {
						if (direction === 'down') {
							var offset =
								$(this.element).find('.row:last-child').offset()
									.top - $(this.element).offset().top
							$(this.element)
								.removeClass('fixed')
								.addClass('fixed-bottom')
							$(this.element)
								.find('.fixed-item')
								.css({ top: offset })
						} else {
							$(this.element)
								.addClass('fixed')
								.removeClass('fixed-bottom')
							$(this.element)
								.find('.fixed-item')
								.removeAttr('style')
						}
					},
					offset: function () {
						return (
							$(this.element).height() * -1 +
							$(this.element).find('.row').last().outerHeight() +
							100
						)
					}
				})
			}
		})

		var scrollGroupWaypoints = $('section.scroll-group .row-content').each(
			function () {
				$(this).waypoint({
					handler: function (direction) {
						$(this.element).addClass('loaded')
					},
					offset: '77%'
				})
			}
		)

		$('section.count')
			.find('.number')
			.each(function () {
				var options = {}
				var $num = $(this)
				var start = $(this).data('start')
				var end = $(this).data('end')
				options.prefix = $(this).data('prefix')
					? $(this).data('prefix')
					: ''
				options.suffix = $(this).data('suffix')
					? $(this).data('suffix')
					: ''
				options.separator = $(this).data('separator')
					? $(this).data('separator')
					: ','
				options.decimal = $(this).data('decimal')
					? $(this).data('decimal')
					: '.'

				var countAnim = new CountUp(
					$num.attr('id'),
					start,
					end,
					0,
					2,
					options
				)

				var countWp = $(this).waypoint({
					handler: function (direction) {
						if (!countAnim.error) {
							countAnim.start()
						} else {
							console.log(countAnim.error)
						}
					},
					offset: '50%'
				})
			})

		var lastWidth = 1440

		$(window).on('load resize', function () {
			if ($(window).width() > 960 && lastWidth <= 960) {
				$('section.cta-section .right-image [mask]').attr(
					'mask',
					'url(#mask-desktop)'
				)
			} else if ($(window).width() <= 960 && lastWidth > 960) {
				$('section.cta-section .right-image [mask]').attr(
					'mask',
					'url(#mask-tablet)'
				)
			}
			lastWidth = $(window).width()
		})

		// * * * * * * * * * * * * * * * //
		// PROJECT-SPECIFIC JS //
		// * * * * * * * * * * * * * * * //

		// Get the sticky header and the content element
		const header = document.querySelector('.header')
		const contentElement = document.querySelector('.scales-framework')
		const scalesContainer = document.getElementById('scales-container')
		const pinContainer = document.getElementById('pin-container')
		const scrollContainer = document.getElementById('scroll-container')

		if (scalesContainer) {
			// Function to handle scroll events
			function handleScroll () {
				// Get the bounding rect of the sticky header and the content element
				const headerRect = header.getBoundingClientRect()
				const contentRect = contentElement.getBoundingClientRect()
				const lastCard = document.querySelector(
					'.spatiotemporal-scale-card:last-child'
				)
				const lastCardRect = lastCard.getBoundingClientRect()

				if (lastCardRect.top <= headerRect.bottom) {
					scalesContainer.classList.remove('fixed')
					pinContainer.classList.remove('pin-spacer')
					scrollContainer.classList.add('end')
				} else if (contentRect.top <= headerRect.bottom) {
					scalesContainer.classList.add('fixed')
					pinContainer.classList.add('pin-spacer')
				} else {
					scalesContainer.classList.remove('fixed')
					pinContainer.classList.remove('pin-spacer')
					scrollContainer.classList.remove('end')
				}
			}

			// Add the scroll event listener to the window
			window.addEventListener('scroll', handleScroll)

			const cardContainer = document.querySelector('.card-container')
			const fadeImages = document.querySelectorAll('.fade-img')
			let currentVisibleImage = null

			// Function to handle intersection changes for the card elements
			function handleCardIntersection (entries) {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						// console.log('intersecting');
						// Get the unique identifier of the intersecting card (e.g., data-card-id)
						const cardId = entry.target.getAttribute('data-card-id')
						// Find the corresponding fade-img element based on the cardId
						const fadeImage = document.querySelector(
							`.fade-img[data-img-id="${cardId}"]`
						)
						// Check if the intersecting card's image is different from the currently visible image
						if (fadeImage !== currentVisibleImage) {
							// Fade out the previously visible image if it exists
							if (currentVisibleImage) {
								currentVisibleImage.style.opacity = 0
							}

							// Apply the fade-in effect to the corresponding fade-img element
							fadeImage.style.opacity = 1

							// Update the currently visible image to the new image
							currentVisibleImage = fadeImage
						}
					} else {
						// Optionally, set the opacity back to 0 to hide the image again
						const cardId = entry.target.getAttribute('data-card-id')
						const fadeImage = document.querySelector(
							`.fade-img[data-img-id="${cardId}"]`
						)
						if (fadeImage && fadeImage === currentVisibleImage) {
							fadeImage.style.opacity = 0
							currentVisibleImage = null // Reset the currentVisibleImage when it is not intersecting any more
						}
					}
				})
			}

			// Create an Intersection Observer instance for card elements
			const cardObserver = new IntersectionObserver(
				handleCardIntersection,
				{
					threshold: 0.75 // Adjust the threshold value as needed
				}
			)

			// Observe each card element in the card-container
			cardContainer
				.querySelectorAll('.spatiotemporal-scale-card')
				.forEach((card, index) => {
					// Assign a unique identifier to each card using a data attribute (e.g., data-card-id)
					card.setAttribute('data-card-id', index)
					cardObserver.observe(card)
				})
		}

		// Get all the expand buttons
		const expandButtons = document.querySelectorAll('.expand.button')
		const gridItems = document.querySelectorAll(
			'.grid__item,.headshot-grid__item'
		)
		const gridDescriptions = document.querySelectorAll('.grid__description')

		// Function to handle expand button click
		function handleExpandButtonClick (button, index) {
			const gridItem = gridItems[index]
			const gridDescription = gridDescriptions[index]

			// Check if the clicked grid item is already open
			const isOpen = gridItem.classList.contains('active')

			// Close all other open grid items and descriptions
			gridItems.forEach(item => {
				item.classList.remove('active')
				item.setAttribute('aria-hidden', 'true')
			})

			gridDescriptions.forEach(description =>
				description.classList.remove('active')
			)

			// Toggle the "active" class on the grid item and grid description
			if (!isOpen) {
				gridItem.classList.add('active')
				// gridItem.setAttribute('aria-hidden', 'true')
				gridDescription.classList.add('active')
			} else {
				gridItem.setAttribute('aria-hidden', 'false')
			}

			// Toggle the text and icon of the expand button
			const icon = button.querySelector('.icon')
			if (isOpen) {
				button.innerHTML = 'Expand<span class="icon plus"></span>'
				icon.classList.remove('minus')
				icon.classList.add('plus')
				button.setAttribute('aria-expanded', 'true')
			} else {
				button.innerHTML = 'Collapse<span class="icon minus"></span>'
				icon.classList.remove('plus')
				icon.classList.add('minus')
				button.setAttribute('aria-expanded', 'false')
			}
		}

		// Attach a click event listener to each expand button
		expandButtons.forEach((button, index) => {
			button.addEventListener('click', () => {
				handleExpandButtonClick(button, index)
			})
		})

		// Get all the collapse buttons
		const collapseButtons = document.querySelectorAll('.collapse.button')

		// Function to handle collapse button click
		function handleCollapseButtonClick (button, index) {
			const gridItem = gridItems[index]
			const gridDescription = gridDescriptions[index]

			// Toggle the "active" class on the grid item and grid description
			const isOpen = gridItem.classList.contains('active')
			gridItem.classList.toggle('active')
			gridDescription.classList.toggle('active')

			// Get the corresponding expand button and toggle its text back to "Expand"
			const expandButton = expandButtons[index]
			expandButton.innerHTML = 'Expand<span class="icon plus"></span>'

			// Toggle the icon of the collapse button
			// const icon = button.querySelector('.icon')
			// icon.classList.toggle('minus')
			// icon.classList.toggle('light')
		}

		// Attach a click event listener to each collapse button
		collapseButtons.forEach((button, index) => {
			button.addEventListener('click', () => {
				handleCollapseButtonClick(button, index)
			})
		})

		// Engage Accordion Open/Close
		const engageLinks = document.querySelectorAll('.engage-link')
		const engageContainer = document.getElementById('ways-to-engage')

		// Define a variable to keep track of the currently active engage link
		let activeLinkIndex = null

		// Attach a click event listener to each engage link
		engageLinks.forEach((link, index) => {
			link.addEventListener('click', () => {
				// If the clicked link is already active, remove the active class from it
				if (activeLinkIndex === index) {
					link.classList.remove('active')
					engageContainer.classList.remove('active')
					const engageDetails = document.getElementById(
						`engage-details-${index + 1}`
					)
					engageDetails.classList.remove('active')
					activeLinkIndex = null // No link is active
				} else {
					// Get the currently active link and remove the active class from it
					if (activeLinkIndex !== null) {
						const activeLink = engageLinks[activeLinkIndex]
						activeLink.classList.remove('active')
						const activeEngageDetails = document.getElementById(
							`engage-details-${activeLinkIndex + 1}`
						)
						activeEngageDetails.classList.remove('active')
					}

					// Add the active class to the clicked link and set it as the currently active link
					link.classList.add('active')
					engageContainer.classList.add('active')
					const engageDetails = document.getElementById(
						`engage-details-${index + 1}`
					)
					engageDetails.classList.add('active')
					activeLinkIndex = index
				}
			})
		})

		let homepage = Array.from(document.body.classList).includes('home')

		var smallImages = document.querySelectorAll('.small-img-container')
		new simpleParallax(smallImages, {
			scale: 1.1,
			delay: 0.6,
			transition: 'cubic-bezier(0,0,0,1)'
		})

		// var bgImages = document.querySelectorAll('.bg-img')
		// new simpleParallax(bgImages, {
		//   scale: 1.1,
		//   delay: 0.2,
		//   transition: 'cubic-bezier(0,0,0,1)'
		// })

		// Change large-stat font size depending on character length
		const largeStat = Array.from(
			document.querySelectorAll('.large-stat .large-stat__num')
		)

		function scaleFontSize () {
			largeStat.forEach(stat => {
				if (stat.innerHTML.length >= 4) {
					stat.style.fontSize = '72px'
				}
			})
		}

		if (largeStat) {
			scaleFontSize()
		}

		const headerLogo = document.getElementById('header-shrink')
		const notHomepage = document.getElementById('not-homepage')
		const mobileLogo = document.getElementById('mbl-logo')

		if (homepage) {
			headerLogo.classList.add('homepage')
			notHomepage.classList.add('homepage')
		} else {
			mobileLogo.classList.add('not-homepage')
		}

		const sectionToAnimate = document.querySelector('.animate')

		if (sectionToAnimate) {
			// Function to add the class when the section is in view
			function animateOnScroll (entries, observer) {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						entry.target.classList.add('animate-on-scroll-active')
						observer.unobserve(entry.target) // Optional: Stop observing once the animation is triggered
					}
				})
			}

			// Create the Intersection Observer
			const options = {
				threshold: 0.5
			}

			const animateOnScrollObserver = new IntersectionObserver(
				animateOnScroll,
				options
			)

			// Observe the section with the .animate-on-scroll class
			animateOnScrollObserver.observe(sectionToAnimate)
		}

		function logoShrink () {
			if (
				document.body.scrollTop > 5 ||
				document.documentElement.scrollTop > 5
			) {
				headerLogo.classList.add('shrink')
				mobileLogo.classList.add('show')
			} else {
				headerLogo.classList.remove('shrink')
				mobileLogo.classList.remove('show')
			}
		}

		window.onscroll = function () {
			logoShrink()
		}
	},
	finalize () {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
}
